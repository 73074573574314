import  { useState, useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'
import { Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download';
import LoadingButton from '@mui/lab/LoadingButton'
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'

import './testplans.css'
import * as api from '../../../data/api-types'
import { useSelector } from 'react-redux';
import { selectUserlabAccessToken } from '../main/adminSlice';
import { getRawResponses, getTestplanSummaries } from '../../../data/api-io';
import { TestplanDetail } from './details/testplanDetail'
import { PillID, PillMethodIndependent, PillMethodPaired } from '../pills/pills'


interface TestplansProps{
    check401: (e: any)=>void
    check403: (e: any)=>void
}
export function Testplans(props: TestplansProps){
    const userlabAccessToken = useSelector(selectUserlabAccessToken)
    const [testplanList, setTestplanList] = useState<api.TestPlanSummary[]|null>(null)
    const [responsesDownloading, setRespDownloading] = useState<number|null>(null)
    const check401 = props.check401
    const check403 = props.check403
    const url = useRouteMatch()
    const history = useHistory()

    useEffect(()=>{
        if (testplanList === null && userlabAccessToken !== null){
            getTestplanSummaries(userlabAccessToken)
                .then(res => {
                    setTestplanList(res.data)
                })
                .catch(e => {
                    check403(e)
                    check401(e)
                    console.error(e)
                })
        }
    }, [testplanList, userlabAccessToken, check403, check401])

    const onDownloadResponses = (testplanId: number) => {
        if (userlabAccessToken !== null){
            setRespDownloading(testplanId)
            getRawResponses(userlabAccessToken, testplanId, ()=>setRespDownloading(null))
        }
    }

    const onRowSelected = (testplanId: number) => {
        history.push(`${url.path}/${testplanId}`)
    }

    let content = (
        <div id="manage-admins-spinner-container">
            <CircularProgress />
        </div>
    )
    if (testplanList !== null ) {
        content = (
            <div id="testplans-table">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell align="center">id</TableCell>
                    <TableCell align="left">name</TableCell>
                    <TableCell align="left">description</TableCell>
                    <TableCell align="center">method</TableCell>
                    <TableCell align="left">responses</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {testplanList
                    .sort((a, b)=>{return a.id < b.id ? 1 : -1})
                    .map(t => {
                        return (
                        <TableRow
                        hover
                        onClick={() => onRowSelected(t.id)}
                        key={t.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row" align="center">
                            <PillID text={t.id.toString()} />
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {t.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {t.description}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                            {
                                t.mode === api.TestPlanMethod.Independent ?
                                <PillMethodIndependent />
                                :
                                <PillMethodPaired />
                            }
                        </TableCell>
                        <TableCell align="center">
                            <LoadingButton
                                variant="outlined" 
                                color="secondary" 
                                onClick={()=>{onDownloadResponses(t.id)}}
                                loading={responsesDownloading === t.id}
                            >
                                <DownloadIcon />
                            </LoadingButton>
                        </TableCell>
                        </TableRow>
                        )
                    })
                }
                </TableBody>
            </Table>
            </div>
        )
    }
    return (
        <div id="testplans-page">
            <Switch>
            <Route path={`${url.path}/:testplanId`}>
                <TestplanDetail onNavigateBack={()=>{history.push(url.path)}} check401={props.check401}/>
            </Route>
            <Route path={url.path}>
                <div id="testplans-content">
                    <h1>Testplans</h1>
                    {content}
                </div>
            </Route>
            </Switch>
        </div>
    )
}